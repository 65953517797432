import Slider from 'rc-slider';
import React from 'react';

import Page3Donut from './page3donut.svg';

const Page3 = ( props ) => {

  const marks = {
    15: { style: { font: '500 14px Poppins', color: '#69707c' }, label: '15' },
    30: { style: { font: '500 14px Poppins', color: '#69707c' }, label: '30' },
    45: { style: { font: '500 14px Poppins', color: '#69707c' }, label: '45' },
    60: { style: { font: '500 14px Poppins', color: '#69707c' }, label: '60' },
    75: { style: { font: '500 14px Poppins', color: '#69707c' }, label: '75' }
  };

  const width = () => {
    let output;
    if ( props.chanceCoverage ) {
      output = [ props.chanceCoverage.chance, 100 - props.chanceCoverage.chance ];
    } else {
      output = [ 200, 200 ];
    }
    return output;
  };
  return (
    <div className="page-3 h-100 flex-column">

      <div className="d-flex flex-row row1">
        <div className="w-25">
          <img src="" alt="blank" />
        </div>

        <div className="w-75">
          <div className="label">What is your current age?</div>

          <div className='fs-slider-block'>
            <div className="slider-aligned">
              <Slider
                min={10}
                max={80}
                value={props.age}
                onChange={props.onAgeChange}
                onAfterChange={props.onAfterChangeAge}
                marks={marks}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="svg-container">
        <section className="svg">
          <Page3Donut width={width()} perc={props.chanceCoverage ? props.chanceCoverage.chance : 200} />
        </section>
      </div>

      <div className="info">
                Great! You have a {props.chanceCoverage ? props.chanceCoverage.chance + '%' : 'N/A'} chance of getting a coverage upto ${props.chanceCoverage ? props.chanceCoverage.coverage : '~$300,000'} without a medical checkup!
      </div>
    </div>
  );
};

export default Page3;
