import React from 'react';

const colors = [ '#00a12f', 'rgba(218, 231, 236, 0.11)' ]; //


function polarToCartesian( centerX, centerY, radius, angleInDegrees ) {
  var angleInRadians = ( angleInDegrees - 90 ) * Math.PI / 180.0;

  return {
    x: centerX + ( radius * Math.cos( angleInRadians ) ),
    y: centerY + ( radius * Math.sin( angleInRadians ) )
  };
}

function describeArc( x, y, radius, startAngle, endAngle ) {

  var start = polarToCartesian( x, y, radius, endAngle );
  var end = polarToCartesian( x, y, radius, startAngle );

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join( ' ' );

  return d;
}

class SVGDonut extends React.Component {

  render() {
    const arc = [];
    let first = 0, last = 0;
    if ( !this.props ) this.props = {};
    const widthArray = this.props.width || [];
    for ( let i = 0; i < 2; i++ ) {
      last = first + widthArray[i];
      arc.push( <path key={i} fill="transparent" d={describeArc( 140, 140, 100, 180 * ( 1 + first / 100 ), 180 * ( 1 + last / 100 ) )} stroke={colors[i]} strokeWidth="45" /> );
      first = widthArray[i];
    }

    const borders = [ 77, 123 ].map( ( r ) => <path key={r} fill="transparent" d={describeArc( 140, 140, r, 180, 360 )} stroke="#dae7ec" strokeWidth="1" /> );
    const { perc } = this.props;
    const { x, y } = polarToCartesian( 140, 140, 75, 180 + perc / 100 * 180 );

    return (
      <svg width="250" height="300" xmlns="http://www.w3.org/2000/svg">
        <polygon points={`140 140, ${x} ${y}`} stroke="#dae7ec" strokeWidth="10" />

        {borders}

        <line y1="64" y2="16" x1="140" x2="140" stroke="#dae7ec" strokeWidth="1" />

        {arc}

        <text x="-45" y="120" fill="white" transform="rotate(270 20,90)"> {perc}% </text>
      </svg> );
  }
}

export default SVGDonut;
